import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-icon.svg'
import { ReactComponent as XstrelaLogo } from 'assets/icons/xstrela-logo-flat.svg'
import profilePlaceHolder from 'assets/images/placeholderProfile.png'
import { homePath } from 'logic/paths'
import { setInitialActivityReportFilters } from 'logic/redux/actions/activityReport'
import { setInitialUserReportFilters } from 'logic/redux/actions/userReport'
import { useAppDispatch, useAppSelector } from 'logic/redux/hooks'
import React, { useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useAdminRole } from 'shared/hooks/useAdminRole'
import useLogout from 'shared/hooks/useLogout'
import { Col, Row } from 'shared/styled'
import { BoldText3, Text1, Text3 } from 'shared/typography'
import { withTheme } from 'styled-components'
import { Theme } from 'styles/theme'
import { ADMIN_ROLES } from 'utils/enums'
import {
  PUBLISHER_SIDEBAR_ITEMS,
  SidebarItem,
  SUB_ADMIN_SIDEBAR_ITEMS,
  SUPER_ADMIN_SIDEBAR_ITEMS,
} from './items'
import * as Styles from './style'
import Timeline from './TimelineItem'
import { getUserName } from 'utils/helpers/stringHelpers'

interface ISidebarProps {
  className?: string
  theme?: Theme
}

export const Sidebar: React.FC<ISidebarProps> = withTheme(
  ({ className, theme }: ISidebarProps) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch() // Use dispatch from Redux
    const logout = useLogout()
    const location = useLocation()
    const adminRole = useAdminRole()

    const { profileDetail } = useAppSelector((state: any) => state.adminReducer)

    const [expandedItem, setExpandedItem] = useState<string | null>(null)

    const toggleAccordion = (label: string, isAccordion?: boolean) => {
      if (isAccordion)
        setExpandedItem((prev) => (prev === label ? null : label))
    }

    const getSideBarItems = (role: string) => {
      switch (role) {
        case ADMIN_ROLES.SUPER_ADMIN:
          return SUPER_ADMIN_SIDEBAR_ITEMS
        case ADMIN_ROLES.SUB_ADMIN:
          return SUB_ADMIN_SIDEBAR_ITEMS
        case ADMIN_ROLES.PUBLISHER:
          return PUBLISHER_SIDEBAR_ITEMS
        default:
          return SUPER_ADMIN_SIDEBAR_ITEMS
      }
    }

    const SIDEBAR_ITEMS = getSideBarItems(adminRole)

    useEffect(() => {
      SIDEBAR_ITEMS?.forEach((item) => {
        if (item.isAccordion) {
          setExpandedItem(location.pathname)
        }
      })
    }, [SIDEBAR_ITEMS]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleNavLinkClick = (itemLabel: string) => {
      if (itemLabel === 'Activity Report') {
        dispatch(setInitialActivityReportFilters())
      }
      if (itemLabel === 'User Report') {
        dispatch(setInitialUserReportFilters())
      }
      setExpandedItem(null)
    }

    const renderSidebarItems = () =>
      SIDEBAR_ITEMS?.map((item: SidebarItem) =>
        item.items ? (
          <Col
            key={item.label}
            width='100%'
            marginLeft='12px'
            className='subContainer'
          >
            <NavLink
              to='#'
              onClick={() => toggleAccordion(item.path, item.isAccordion)}
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Row gap='12px' align='center'>
                {item.icon}
                <BoldText3>{item.label}</BoldText3>
                <ArrowIcon
                  style={{
                    marginLeft: 'auto',
                    transform:
                      item.path === expandedItem
                        ? 'rotate(0deg)'
                        : 'rotate(180deg)',
                    transition: 'transform 0.3s ease',
                  }}
                />
              </Row>
            </NavLink>
            {expandedItem === item.path && (
              <Row width='100%' padding='0 !important' justify='end'>
                <Styles.SubSidebarContainer style={{ width: '90%' }}>
                  {/* @ts-ignore */}
                  <Timeline items={item.items} rootPath={item.path} />
                </Styles.SubSidebarContainer>
              </Row>
            )}
          </Col>
        ) : (
          <NavLink
            key={item.path}
            className={({ isActive }) =>
              `${
                isActive ? 'active-route animate__animated' : 'inactive-route'
              } navLink icon-${item.label}`
            }
            to={item.path}
            onClick={() => handleNavLinkClick(item.label)}
          >
            <Row gap='12px' align='center'>
              {item.icon}
              <BoldText3>{item.label}</BoldText3>
            </Row>
          </NavLink>
        )
      )

    const renderProfileDetails = () => (
      <Styles.AdminDetailsContainer>
        <Styles.AdminAvatar
          src={
            profileDetail?.dp_url ||
            profileDetail?.dp_image_url ||
            profilePlaceHolder
          }
          alt='admin-dp'
        />
        <Col gap='1px'>
          <Text1>{getUserName(profileDetail)}</Text1>
          <Text3 color={theme?.maastrichtBlue} id='logoutText' onClick={logout}>
            Logout
          </Text3>
        </Col>
      </Styles.AdminDetailsContainer>
    )

    return (
      <Styles.SidebarWrapper className={className}>
        <Styles.LogoContainer
          onClick={() => navigate(homePath)}
          data-testid='logo'
        >
          <Styles.LogoWrapper>
            <XstrelaLogo />
          </Styles.LogoWrapper>
        </Styles.LogoContainer>
        <Styles.SidebarContainer>
          {renderSidebarItems()}
        </Styles.SidebarContainer>
        {renderProfileDetails()}
      </Styles.SidebarWrapper>
    )
  }
)
