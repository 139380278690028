import styled, { css, keyframes } from 'styled-components'

const animateLoader = keyframes`
  from {transform : rotate(0deg)}
  to {transform : rotate(360deg)}
`

// here in the svg you can add the svg according and can animate
const svgCSS = css`
  font-size: 26px;
  animation: ${animateLoader} linear 2s infinite;
`
const buttonStyles = css<IButtonWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  min-height: ${(props: any) => (props.noMinHeight ? 'none' : '40px')};
  min-width: ${(props: any) => (props.noMinWidth ? 'none' : '120px')};
  border-radius: 50px;
  cursor: pointer;
  transition: all linear 0.3s;
  border: none;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  :disabled {
    opacity: 0.5;
    filter: none !important;
    cursor: default;
  }
  white-space: nowrap;
  border: 1px solid transparent;
  font-size: ${(props) => props.fSize ?? '14px'};
  line-height: ${(props) => props.lineHeight ?? '21px'};
  ${({ disableCircularLoadAnimation }) =>
    !disableCircularLoadAnimation &&
    css`
      svg {
        ${svgCSS}
      }
    `}
  :disabled {
    ::after {
      display: none !important;
    }
    pointer-events: none !important;
  }
`
const primaryHoverEffect = css`
  z-index: 0;
  overflow: hidden;
  position: relative;

  ::after {
    background-color: ${(props) => props.theme.secondary};
    border-radius: 3rem;
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-100%, 0) rotate(10deg);
    transform-origin: top left;
    transition: 0.4s transform ease-out;
    will-change: transform;
    z-index: -1;
  }
  :hover::after {
    transform: translate(0, 0);
  }
  :hover {
    color: ${(props) => props.theme.primary};
    box-shadow: 0px 15px 25px -5px #9163cc3b;
    transform: scale(1.01);
    will-change: transform;
  }
  :active {
    box-shadow: 0px 4px 8px ${(props) => props.theme.primary};
    transform: scale(0.98);
  }
`
const secondaryHoverEffect = css`
  ${primaryHoverEffect};
  ::after {
    background-color: ${(props) => props.theme.primary};
  }
  :hover {
    color: ${(props) => props.theme.secondary};
  }
`

const primary = css`
  background-color: ${(props: any) => props.theme.primary};
  color: ${(props: any) => props.theme.secondary};

  ${primaryHoverEffect};

  :disabled {
    opacity: 0.5;
    background-color: ${(props: any) => props.theme.primary};
    color: ${(props: any) => props.theme.secondary};
    filter: none !important;
    cursor: default;
  }
`

const primaryS = css`
  ${primary};
  padding: 0;
  min-height: 30px;
  min-width: 50px;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
`

const primaryF = css`
  ${primary};
  padding: 5px 16px;
  min-height: 0;
  min-width: 110px;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
`

const secondary = css`
  background-color: ${(props: any) => props.theme.secondary};
  color: ${(props: any) => props.theme.primary};
  border: 1px solid ${(props: any) => props.theme.primary};
  ${secondaryHoverEffect};

  :disabled {
    opacity: 0.5;
    background-color: ${(props: any) => props.theme.secondary};
    color: ${(props: any) => props.theme.primary};
    filter: none !important;
    cursor: default;
  }
`

const secondaryS = css`
  ${secondary};
  min-height: 32px;
  min-width: 76px;
`
const deleteS = css`
  ${secondaryS};
  color: ${(props) => props.theme.error};
  border-color: ${(props) => props.theme.error};
  ${primaryHoverEffect};
  ::after {
    background-color: ${(props) => props.theme.error};
  }
  :hover {
    color: ${(props) => props.theme.secondary};
  }
`
const primaryRed = css`
  ${primary};
  background-color: ${(props: any) => props.theme.error};
  color: ${(props: any) => props.theme.secondary};
  transition: 300ms linear;
  border: 1px solid ${(props) => props.theme.error};

  :hover {
    color: ${(props) => props.theme.error};
  }
`

const secondaryIconBtn = css`
  ${secondary};
  min-width: 44px;
  min-height: 44px;
  border-radius: 50%;
  padding: 0;
`
const textBtn = css`
  ${secondary};
  min-width: 44px;
  min-height: 44px;
  padding: 0;
  background-color: transparent;
  border: none !important;
`
const linkBtn = css`
  color: ${(props) => props.theme.primary};
  text-decoration: underline;
  min-width: 44px;
  min-height: 44px;
  padding: 0;
  background-color: transparent;
  border: none !important;
`
const textBtnDanger = css`
  ${deleteS};
  min-width: 44px;
  min-height: 44px;
  padding: 0;
  background-color: transparent;
  border: none !important;
`

const buttonWrapperStyles: any = {
  primary,
  secondary,
  primaryS,
  deleteS,
  primaryF,
  secondaryS,
  secondaryIconBtn,
  textBtn,
  textBtnDanger,
  primaryRed,
  linkBtn,
}

interface IButtonWrapperProps {
  btnType?: string
  fSize?: string
  fSizeMobile?: string
  disabled?: boolean
  width?: string
  onClick?: Function
  height?: string
  disableCircularLoadAnimation?: boolean
  noMinHeight?: boolean
  noMinWidth?: boolean
  padding?: string
  lineHeight?: string
}

export const ButtonWrapper = styled.button<IButtonWrapperProps>`
  ${buttonStyles}
  ${(props: IButtonWrapperProps) =>
    buttonWrapperStyles[props.btnType ?? 'primary']};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  padding: ${(props) => props.padding};
`

interface IButtonAlignmentProps {
  align?: string
  justify?: string
  width?: string
}

export const ButtonAlignment = styled.div<IButtonAlignmentProps>`
  display: flex;
  align-items: ${(props: any) =>
    (props.align === 'center' && 'center') ||
    (props.align === 'start' && 'flex-start') ||
    (props.align === 'end' && 'flex-end')};
  justify-content: ${(props: any) => props.justify || 'center'};
  width: ${(props) => props.width};
`
