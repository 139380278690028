import React from 'react'
import { BoldText4 } from 'shared/typography'
import * as Styles from './index'

interface ICategoryBadgeProps {
  children?: React.ReactNode
  bgColor: string
  uppercaseText?: boolean
  solidBg?: boolean
}

export const Tag: React.FC<ICategoryBadgeProps> = ({
  children,
  bgColor,
  solidBg,
  uppercaseText = false,
}) => {
  if (!children) {
    return null // Return null if children is not provided
  }

  return (
    <Styles.TagWrapper bgColor={bgColor} data-testid='tag' solidBg={solidBg}>
      <Styles.Dot bgColor={bgColor} solidBg={solidBg} />
      <BoldText4
        width='max-content'
        color={bgColor}
        textTransform={uppercaseText ? 'uppercase' : 'none'}
      >
        {children}
      </BoldText4>
    </Styles.TagWrapper>
  )
}
