import React, { useEffect, useState } from 'react'
import { BolderText3, BoldText4 } from 'shared/typography'
import { withTheme } from 'styled-components'
import {
  CheckboxInput,
  CheckboxLabel,
  CheckboxWrapper,
  Checkmark,
  Icon,
} from './style'

interface CheckboxProps {
  label: string | React.ReactNode
  checked?: boolean
  onChange?: (checked: boolean) => void
  isPrimary?: boolean
  isDisable?: boolean
  className?: string
  isError?: boolean
  theme: any
  testId?: string
  disableToggle?: boolean
  img?: string
  showBoldLabel?: boolean
  showOptionsAsTag?: boolean
}

export const Checkbox = withTheme(
  ({
    label,
    checked,
    onChange,
    isDisable,
    isError,
    className,
    isPrimary,
    theme,
    testId,
    img,
    disableToggle,
    showBoldLabel,
    showOptionsAsTag,
  }: CheckboxProps) => {
    const [isChecked, setIsChecked] = useState<boolean>(Boolean(checked))

    const handleCheckboxChange = () => {
      // const newValue = !isChecked
      if (!disableToggle) {
        setIsChecked(!isChecked)
      }
      onChange?.(!isChecked)
    }

    useEffect(() => {
      setIsChecked(Boolean(checked))
    }, [checked])

    return (
      <CheckboxWrapper
        isDisable={isDisable ?? false}
        className={className}
        data-testid={testId}
      >
        <CheckboxInput
          type='checkbox'
          checked={isChecked}
          onChange={handleCheckboxChange}
          disabled={isDisable ?? false}
          name={label as string}
          id={label as string}
        />
        <Checkmark isDisable={isDisable ?? false} className='checkmark' />

        <CheckboxLabel showOptionsAsTag={showOptionsAsTag}>
          {Boolean(img) && <Icon src={img} alt='icon' />}

          {showBoldLabel ? (
            <BolderText3
              textWrap='nowrap'
              color={isDisable && theme.disableColor}
              textTransform='capitalize'
            >
              {label}
            </BolderText3>
          ) : (
            <BoldText4
              textWrap='nowrap'
              color={isDisable && theme.disableColor}
              textTransform='capitalize'
            >
              {label}
            </BoldText4>
          )}
        </CheckboxLabel>
      </CheckboxWrapper>
    )
  }
)
