import React, { useState } from 'react'
import * as Styles from './style'
import { Text4 } from 'shared/typography'
import Dropdown from 'shared/dropdown'
import { Row, VerticalLine } from 'shared/styled'

/**
 * Interface for pagination component props
 * @interface PaginationProps
 */
interface PaginationProps {
  /**
   * Current page number
   * @type {number}
   */
  currentPage: number

  /**
   * Total number of pages
   * @type {number}
   */
  totalPages: number

  /**
   * Maximum number of pages to display in the pagination control
   * @type {number}
   */
  pageLimit: number

  /**
   * Callback function triggered when the page changes
   * @type {(pageNumber: number) => void}
   */
  onPageChange: (pageNumber: number) => void

  /**
   * Optional: Current limit (number of records per page)
   * @type {number | undefined}
   */
  currentLimit?: number

  /**
   * Optional: Flag to show or hide the records per page dropdown
   * @type {boolean | undefined}
   */
  showRecordsPerPageDropdown?: boolean

  /**
   * Optional: Callback function triggered when the records per page limit changes
   * @type {(recordsPerPage: number) => void | undefined}
   */
  onLimitChange?: (recordsPerPage: number) => void
}

interface IDropdownItem {
  name: string
  value: string
}

const recordsItemsCount: IDropdownItem[] = [
  { name: '10', value: '10' },
  { name: '20', value: '20' },
  { name: '30', value: '30' },
  { name: '40', value: '40' },
  { name: '50', value: '50' },
  { name: '100', value: '100' },
]

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  currentLimit,
  totalPages,
  onPageChange,
  pageLimit = 5,
  onLimitChange,
  showRecordsPerPageDropdown,
}) => {
  const [recordsPerPage, setRecordsPerPage] = useState(currentLimit)
  const [goToPageNumber, setGoToPageNumber] = useState(currentPage)
  const [visiblePages, setVisiblePages] = useState<number[]>(() => {
    const firstVisiblePage = Math.max(
      1,
      currentPage - Math.floor(pageLimit / 2)
    )
    const lastVisiblePage = Math.min(
      totalPages,
      firstVisiblePage + pageLimit - 1
    )
    return Array?.from(
      Array(lastVisiblePage - firstVisiblePage + 1),
      (_, index) => firstVisiblePage + index
    )
  })

  const handlePageChange = (pageNumber: number) => {
    if (pageNumber < 1 || pageNumber > totalPages) {
      return
    }

    if (
      pageNumber < visiblePages[0] ||
      pageNumber > visiblePages[visiblePages.length - 1]
    ) {
      const firstVisiblePage = Math.max(
        1,
        pageNumber - Math.floor(pageLimit / 2)
      )
      const lastVisiblePage = Math.min(
        totalPages,
        firstVisiblePage + pageLimit - 1
      )
      setVisiblePages(
        Array?.from(
          Array(lastVisiblePage - firstVisiblePage + 1),
          (_, index) => firstVisiblePage + index
        )
      )
    }

    onPageChange(pageNumber)
  }

  const handleGoToPage = (e: any) => {
    const inputValue = e.target.value
    const isValid = /^\d*$/.test(inputValue)
    if (isValid) {
      setGoToPageNumber(Number(inputValue))
    }
  }

  if (typeof totalPages !== 'number' || totalPages <= 0) {
    return null // or display an error message
  }

  const handleRecordsPerPageChange = (item: IDropdownItem) => {
    const value = Number(item.value)

    setRecordsPerPage(value)
    onLimitChange?.(value)
  }

  return (
    <Styles.PaginationContainer>
      {showRecordsPerPageDropdown ? (
        <Row align='center'>
          <Text4>Records per Page:</Text4>
          <Dropdown
            headerText={recordsPerPage?.toString()}
            options={recordsItemsCount}
            onChange={handleRecordsPerPageChange}
            className='recordsPerPage'
            headerClassName='dropdownHeader'
            testId='dropdown'
          />
        </Row>
      ) : null}

      <ul className='pagination'>
        <li className={`page-item${currentPage === 1 ? ' disabled' : ''}`}>
          <Styles.PageNavBtn
            className='page-link'
            onClick={() => handlePageChange(currentPage - 1)}
            isDisabled={currentPage === 1}
          >
            {'<'}
          </Styles.PageNavBtn>
        </li>
        {visiblePages.map((number) => (
          <li
            key={number}
            className={`page-item${currentPage === number ? ' active' : ''}`}
          >
            <Styles.PageNumberBtn
              className='page-link'
              onClick={() => handlePageChange(number)}
              isDisabled={number === currentPage}
              data-testid={number}
            >
              {number}
            </Styles.PageNumberBtn>
          </li>
        ))}
        <li
          className={`page-item${
            currentPage === totalPages ? ' disabled' : ''
          }`}
        >
          <Styles.PageNavBtn
            className='page-link'
            onClick={() => handlePageChange(currentPage + 1)}
            isDisabled={currentPage === totalPages}
          >
            {'>'}
          </Styles.PageNavBtn>
        </li>
      </ul>
      <VerticalLine />
      <Styles.NumberInput
        value={goToPageNumber}
        onChange={handleGoToPage}
        type='number'
        min={1}
        step={1}
        max={totalPages}
        data-testid='number-input'
      />
      <Styles.PageNumberBtn
        className='page-link'
        onClick={() => handlePageChange(Number(goToPageNumber))}
        isDisabled={currentPage === Number(goToPageNumber)}
      >
        {'Go >'}
      </Styles.PageNumberBtn>
    </Styles.PaginationContainer>
  )
}

export default Pagination
