import { Row } from 'shared/styled'
import styled, { css } from 'styled-components'

export const TooltipContainer = styled(Row)`
  justify-content: center;
  align-items: center;
  gap: 2px;
  position: relative;
  cursor: pointer;
  text-align: center;
`

export const TooltipText = styled.span<{
  direction?: string
  variant?: 'default' | 'dark'
}>`
  visibility: hidden;
  width: max-content;
  max-width: 220px;
  text-align: center;

  border-radius: ${(props) => (props.variant === 'dark' ? '12px' : '5px')};
  padding: 8px 12px;
  position: absolute;
  z-index: 2147483647; /* Maximum z-index value */
  opacity: 0;
  transition: opacity 0.3s;

  /* Ensure text wrapping */
  white-space: normal; /* Allows text to wrap onto the next line */
  word-wrap: break-word; /* Breaks long words if necessary */

  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  ${(props) =>
    props.variant === 'default'
      ? css`
          background-color: ${props.theme.secondary};
          color: ${props.theme.primaryText2};
          border: 1px solid ${props.theme.secondary};
          box-shadow: 0px 3px 13px 0px rgba(145, 99, 204, 0.13);
        `
      : css`
          background-color: #333;
          color: #fff;
          border: 1px solid #444;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
        `}

  ${(props) => {
    switch (props.direction) {
      case 'bottom':
        return `
          left: 50%;
          top: 100%;
          transform: translateX(-50%);
        `
      case 'left':
        return `
          right: 150%;
          top: 50%;
          transform: translateY(-50%);
        `
      case 'right':
        return `
          left: 150%;
          top: 50%;
          transform: translateY(-50%);
        `
      default:
        // top by default
        return `
          left: 50%;
          bottom: 150%;
          transform: translateX(-50%);
        `
    }
  }}

  ${TooltipContainer}:hover & {
    visibility: visible;
    opacity: 1;
  }

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    ${(props) => {
      switch (props.direction) {
        case 'bottom':
          return `
            top: -6px;
            left:46%;
            transform: translateX(-50%);
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 6px solid ${
              props.variant === 'default' ? props.theme.secondary : '#333'
            };
          `
        case 'left':
          return `
            top: 50%;
            right: -6px;
            transform: translateY(-50%);
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
            border-left: 6px solid ${
              props.variant === 'default' ? props.theme.secondary : '#333'
            };
          `
        case 'right':
          return `
            top: 50%;
            left: -6px;
            transform: translateY(-50%);
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
            border-right: 6px solid ${
              props.variant === 'default' ? props.theme.secondary : '#333'
            };
          `
        default:
          return `
            bottom: -6px;
            left: 46%;
            transform: translateX(-50%);
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid ${
              props.variant === 'default' ? props.theme.secondary : '#333'
            };
          `
      }
    }}
  }
`
