import * as chrono from 'chrono-node'
import moment, { Moment } from 'moment'
import momentTimezone from 'moment-timezone'
import { trimString } from './stringHelpers'

const SHOW_TIMEZONE = true // Set this to false to hide the timezone

export const formattedDate = (dateString: any) => {
  if (!dateString) return 'N/A'

  // Base format for the date
  let formatString = 'MMMM DD, YYYY [at] h:mm a'

  const userTimezone = momentTimezone.tz.guess() // Detect the user's local timezone
  const formattedDate = momentTimezone
    .utc(dateString) // Treat the input as UTC
    .tz(userTimezone) // Convert to local timezone
    .format(formatWithTimezone(formatString)) // Format with timezone abbreviation (e.g., IST)

  return formattedDate
}

export function timestampToTimeAgo(timestamp = ''): string {
  if (!timestamp) return 'N/A'
  const currentMoment: Date = new Date()
  const pastMoment: Date | null = chrono.parseDate(timestamp)

  if (!pastMoment) return 'Invalid Timestamp'

  const duration: number = currentMoment.getTime() - pastMoment.getTime()

  const years = Math.floor(duration / (1000 * 60 * 60 * 24 * 365))
  const months = Math.floor((duration / (1000 * 60 * 60 * 24 * 30)) % 12)
  const weeks = Math.floor((duration / (1000 * 60 * 60 * 24 * 7)) % 4)
  const days = Math.floor((duration / (1000 * 60 * 60 * 24)) % 7)
  const hours = Math.floor((duration / (1000 * 60 * 60)) % 24)
  const minutes = Math.floor((duration / (1000 * 60)) % 60)
  const seconds = Math.floor((duration / 1000) % 60)

  const timeAgo = []
  if (years) {
    timeAgo.push(`${years} y`)
  }
  if (months) {
    timeAgo.push(`${months} m`)
  }
  if (weeks) {
    timeAgo.push(`${weeks} w`)
  }
  if (days) {
    timeAgo.push(`${days} d`)
  }
  if (hours) {
    timeAgo.push(`${hours} h`)
  }
  if (minutes) {
    timeAgo.push(`${minutes} min`)
  }
  if (seconds) {
    timeAgo.push(`${seconds} sec`)
  }
  return timeAgo.join(', ') + ' ago'
}

export function convertTimeFilter(timeString: string): string | null {
  if (timeString.toString() === 'Invalid Date') return null

  const momentObj = moment(timeString, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ')
  const temp = momentObj.hour(23).minute(59).second(59)
  const date = temp.format('YYYY-MM-DDTHH:mm:ss.SSSSSS')
  return date === 'Invalid date' ? 'N/A' : date
}

export function convertTimeFilter2(timeString: string): string {
  const momentObj = moment(timeString)
  const date = momentObj.format('YYYY-MM-DDTHH:mm:ss.SSSSSS')
  return date === 'Invalid date' ? 'N/A' : date
}

export function convertTimeFormat(timeString: string): string | null {
  if (!timeString || timeString.toString() === 'Invalid Date') return null

  const momentObj = moment(timeString, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ')
  const date = momentObj.format('YYYY-MM-DDTHH:mm:ss.SSSSSS')

  return date === 'Invalid date' ? 'N/A' : date
}

export function convertTimeFormat2(timeString: string): string {
  const momentObj = moment(timeString, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ')
  const date = momentObj.format('DD/MM/YY')
  return date === 'Invalid date' ? 'N/A' : date
}

export function convertTimeFormat4(timeString: string): string {
  const momentObj = moment(timeString, 'YYYY-MM-DD')
  const temp = momentObj.add(1, 'days')
  const date = temp.format('YYYY-MM-DDTHH:mm:ss.SSSSSS')
  return date === 'Invalid date' ? '' : date
}

export function convertTimeFormat5(timeString: string): string {
  const momentObj = moment(timeString, 'YYYY-MM-DDTHH:mm:ss.SSSSSS')
  const date = momentObj.format('DD/MM/YYYY')
  return date === 'Invalid date' ? 'N/A' : date
}

export const convertTimeFormat6 = (timeString: string): string => {
  if (!timeString) return 'N/A'

  // Parse the date using chrono
  const pastMoment = chrono.parseDate(timeString)
  if (!pastMoment) return 'N/A'

  // Guess the user's local timezone
  const userTimezone = momentTimezone.tz.guess()

  // Base format string
  let formatString = 'DD MMM YYYY | hh:mm A'

  // Use the separate function to append timezone if needed
  formatString = formatWithTimezone(formatString)

  // Convert the date to the user's local timezone and format it
  return momentTimezone(pastMoment).tz(userTimezone).format(formatString)
}
export const convertTimeFormat8 = (timeString: string): string => {
  if (!timeString) return 'N/A'

  // Parse the date using chrono
  const parsedDate = chrono.parseDate(timeString)
  if (!parsedDate) return 'N/A'

  // Guess the user's local timezone
  const userTimezone = moment.tz.guess()

  // Define the format string
  const formatString = 'h:mm A - M/D/YYYY'

  // Convert the date to the user's local timezone and format it
  return moment(parsedDate).tz(userTimezone).format(formatString)
}

export const convertTimeToUTC = (timeString: string | undefined): string => {
  if (!timeString) return 'N/A'
  const pastMoment: Date | null = chrono.parseDate(timeString)

  const momentObj = moment(pastMoment, 'YYYY-MM-DDTHH:mm:ss')
  const formattedDate = momentObj.format('MMM DD, YYYY')

  return formattedDate
}

export const convertTimeFormat7 = (timeString: string | undefined): string => {
  if (!timeString) return 'N/A'

  const pastMoment: Date | null = chrono.parseDate(timeString) // Parse the input string to a date object

  const momentObj = momentTimezone(pastMoment) // Create a moment object
  const userTimezone = momentTimezone.tz.guess() // Detect the user's local timezone

  // Base format for the date
  let formatString = 'MMM DD, YYYY'

  const formattedDate = momentObj
    .tz(userTimezone)
    .format(formatWithTimezone(formatString))
  return formattedDate
}

/**
 * Converts a time string or Moment object to ISO format.
 *
 * @param {string|Moment} timeString - The input time string or Moment object.
 * @returns {string} - The time string converted to ISO format or 'N/A' if conversion fails.
 *
 * @example
 * // Example 1: Convert a Moment object to ISO format
 * const momentObject = moment('2024-01-31T12:34:56.789Z');
 * const isoResult1 = convertToISOFormat(momentObject);
 * console.log(isoResult1); // Output: '2024-01-31T12:34:56.789Z'
 *
 * @example
 * // Example 3: Handling invalid input
 * const isoResult3 = convertToISOFormat('invalidDateString');
 * console.log(isoResult3); // Output: 'N/A'
 */
export function convertToISOFormat(timeString: string | Moment): string {
  const momentObj = moment(timeString, 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ')
  const isoFormat = momentObj.toISOString()
  return isoFormat === 'Invalid date' ? 'N/A' : isoFormat
}

export const getDayNameTimezone = (timestamp: string) => {
  const timestampInMilliseconds = Number(timestamp) * 1000

  const localOffset = new Date().getTimezoneOffset() // in minutes

  const localOffsetMilliseconds = 60 * 1000 * localOffset

  const date = moment(timestampInMilliseconds + localOffsetMilliseconds)

  const dayName = date.format('dddd')

  return dayName
}

export const getDayHour = (timestamp: string) => {
  const timestampInMilliseconds = Number(timestamp) * 1000
  const date = moment(timestampInMilliseconds)
  return date.hour()
}

export const getDate = (timestamp: string) => {
  const timestampInMilliseconds = Number(timestamp) * 1000
  const date = moment(timestampInMilliseconds)
  return date.date()
}

export const getDateWithMonth = (timestamp: string) => {
  const date = moment(timestamp, 'YYYY-MM-DDTHH:mm:ss.SSS')
  return date.format('MMMDD') // Use 'MMMDD' format to get month abbreviation followed by the day
}

export const getMonth = (timestamp: string) => {
  const timestampInMilliseconds = Number(timestamp) * 1000
  const date = moment(timestampInMilliseconds)
  let monthName = date.format('MMMM')
  monthName = trimString(monthName, 3, true)
  return monthName
}

export const getMonthYear = (timestamp: string) => {
  const timestampInMilliseconds = Number(timestamp) * 1000
  const date = moment(timestampInMilliseconds)
  let monthName = date.format('MMMM')
  monthName = trimString(monthName, 3, true)
  const year = date.year()
  return `${monthName}'${year}`
}

export const getTimeText = (endTime: number, isStart?: boolean) => {
  // Guess the user's local timezone
  const userTimezone = momentTimezone.tz.guess()

  // Convert endTime from seconds to milliseconds and adjust for timezone
  const endTimeMoment = momentTimezone.utc(endTime * 1000).tz(userTimezone)

  // Base format string combining date and time with "at" between them
  let combinedFormat = 'MMM DD, YYYY [at] hh:mm a'

  // Use the separate function to append timezone if needed
  combinedFormat = formatWithTimezone(combinedFormat)

  // Format the date and time together
  const dateTimeText = endTimeMoment.format(combinedFormat)

  // Determine the message based on whether the sale has started or ended
  if (isStart) {
    return `Sale starts on ${dateTimeText}`
  } else {
    if (new Date() > new Date(endTime * 1000)) {
      return `Sale ended on ${dateTimeText}`
    }
    return `Sale ends on ${dateTimeText}`
  }
}

export const formatTime = (dateTimeString: string) => {
  // Base format for the date
  let formatString = 'MMM D, YYYY - h:mma'

  const formattedDate = momentTimezone
    .utc(dateTimeString) // Treat the input as UTC
    .tz(momentTimezone.tz.guess()) // Convert to local timezone
    .format(formatWithTimezone(formatString)) // Format the date
  return formattedDate
}

export const isDateValid = (dateString: string) => {
  const date = new Date(dateString)

  if (dateString !== 'Invalid Date') {
    return !isNaN(date?.getTime())
  } else {
    return false
  }
}

export const timestampToDate = (timestamp: number | null | undefined) => {
  if (!timestamp) return

  const userTimezone = momentTimezone.tz.guess() // Detect the user's local timezone
  const date = momentTimezone.utc(timestamp * 1000) // Multiply by 1000 to convert seconds to milliseconds

  // Base format for the date
  let formatString = 'DD MMM YYYY'

  // Convert to local timezone and format the date
  const dateString = date
    .tz(userTimezone)
    .format(formatWithTimezone(formatString))

  return dateString
}

export const timestampToDateAndTime = (
  timestamp: number | null | undefined
) => {
  if (!timestamp) return

  // Determine if the timestamp is in seconds and convert to milliseconds if needed
  const isSeconds = timestamp < 10000000000 // A rough check for second format
  const adjustedTimestamp = isSeconds ? timestamp * 1000 : timestamp

  const userTimezone = momentTimezone.tz.guess() // Detect the user's local timezone
  const date = momentTimezone.utc(adjustedTimestamp) // Treat the timestamp as UTC

  // Base format for the date
  let formatString = 'DD MMM YYYY | hh:mm A'

  // Convert to local timezone and format the date
  const dateString = date
    .tz(userTimezone)
    .format(formatWithTimezone(formatString))

  return dateString
}

export const timestampToDateAndTime2 = (time: string | null | undefined) => {
  if (!time) return

  const userTimezone = momentTimezone.tz.guess() // Detect the user's local timezone
  const date = momentTimezone.utc(time) // Treat the input string as UTC

  // Base format for the date
  let formatString = 'DD MMM YYYY | hh:mm A'

  // Convert to local timezone and format the date
  const dateString = date
    .tz(userTimezone)
    .format(formatWithTimezone(formatString))

  return dateString
}

export const convertTimezoneToGMT = (offsetInSeconds: number) => {
  // Convert offset to hours and minutes
  const offsetHours = Math.floor(offsetInSeconds / 3600)
  const offsetMinutes = Math.floor((offsetInSeconds % 3600) / 60)

  // Format the result in hours and minutes
  const hours = offsetHours < 0 ? '-' : '+'
  const absOffsetHours = Math.abs(offsetHours)
  const minutes = String(Math.abs(offsetMinutes)).padStart(2, '0')

  // Construct the GMT representation
  const gmtRepresentation = `GMT${hours}${absOffsetHours}:${minutes}`

  return gmtRepresentation
}

export function isoToEpoch(isoDate: string): number {
  const dateObj = new Date(isoDate)
  const epoch = Math.floor(dateObj.getTime() / 1000)
  return epoch
}

export const calculateStartTime = () => {
  const currentTime = new Date()
  currentTime.setMinutes(currentTime.getMinutes() + 15)

  let currentHour = currentTime.getHours()
  let currentMinutes = currentTime.getMinutes()

  // Adjust hour if minutes exceed 59
  if (currentMinutes >= 60) {
    currentMinutes -= 60
    currentHour += 1
  }

  return `${currentHour}:${currentMinutes.toString().padStart(2, '0')}`
}

export const calculateStartDateTime = () => {
  const currentTime = new Date()
  currentTime.setMinutes(currentTime.getMinutes() + 15)

  return currentTime.toString() // This returns the full date-time in the desired format
}

export const getTimeRemaining = (endTime: string) => {
  const endDate = moment(endTime)
  const now = moment()

  // Calculate the duration in milliseconds
  const duration = moment.duration(endDate.diff(now))

  if (duration.asMilliseconds() <= 0) {
    return 'ENDED'
  }

  // Extract hours, minutes, and seconds from the duration
  const hours = Math.floor(duration.asHours())
  const minutes = duration.minutes()
  const seconds = duration.seconds()

  // Format hours, minutes, and seconds with leading zeros
  const formattedHours = hours < 10 ? `0${hours}` : hours
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds

  return `ENDS IN ${formattedHours}H ${formattedMinutes}M ${formattedSeconds}S`
}

const getStartAndEndDate = (period: string) => {
  let start, end

  switch (period) {
    case 'today':
      start = moment().startOf('day')
      end = moment().endOf('day')
      break
    case 'this_week':
      start = moment().startOf('week')
      end = moment().endOf('week')
      break
    case 'last_week':
      start = moment().subtract(1, 'week').startOf('week')
      end = moment().subtract(1, 'week').endOf('week')
      break
    case 'this_month':
      start = moment().startOf('month')
      end = moment().endOf('month')
      break
    case 'last_month':
      start = moment().subtract(1, 'month').startOf('month')
      end = moment().subtract(1, 'month').endOf('month')
      break
    case 'last_3_month':
      start = moment().subtract(3, 'months').startOf('month')
      end = moment()
      break
    case 'last_year':
      start = moment().subtract(1, 'year').startOf('year')
      end = moment().subtract(1, 'year').endOf('year')
      break
    default:
      start = end = null
  }

  return { start, end }
}
const getEndDate = (period: string, startDate?: string) => {
  let start, end
  const baseDate = startDate ? moment(startDate) : moment() // Use startDate if provided, otherwise fallback to current date

  switch (period) {
    case '1week':
      start = baseDate.startOf('day')
      end = baseDate.clone().add(1, 'week').endOf('day') // Add 1 week
      break
    case '1month':
      start = baseDate.startOf('day')
      end = baseDate.clone().add(1, 'month').endOf('day') // Add 1 month
      break
    case '3months':
      start = baseDate.startOf('day')
      end = baseDate.clone().add(3, 'months').endOf('day') // Add 3 months
      break
    case '6months':
      start = baseDate.startOf('day')
      end = baseDate.clone().add(6, 'months').endOf('day') // Add 6 months
      break
    case '1year':
      start = baseDate.startOf('day')
      end = baseDate.clone().add(1, 'year').endOf('day') // Add 1 year
      break
    default:
      start = end = null
  }

  return { start, end }
}
export const convertToIsoFormat = (dateStr: string) => {
  const date = new Date(dateStr.replace(' ', 'T')) // Replace space with 'T' for ISO compatibility
  const isoString = date.toISOString() // Get the full ISO string
  return isoString.slice(0, -1) // Remove 'Z' and keep milliseconds
}
export const convertToIsoFormat2 = (dateStr: string) => {
  return moment(dateStr).format('YYYY-MM-DDTHH:mm:ss.SSS')
}

const getDateRange = (option: string, formatType: 'ISO' | 'Custom' = 'ISO') => {
  const { start, end } = getStartAndEndDate(option)

  if (!start || !end) return {}

  if (formatType === 'ISO') {
    return {
      from: convertToISOFormat(start),
      to: convertToISOFormat(end),
    }
  } else {
    return {
      from: convertTimeFormat(
        moment(start).format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ')
      ),
      to: convertTimeFormat(
        moment(end).format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ')
      ),
    }
  }
}
export const getDateRange2 = (
  option: string,
  formatType: 'ISO' | 'Custom' = 'ISO',
  startDate?: string
) => {
  const { start, end } = getEndDate(option, startDate)

  if (!start || !end) return {}

  if (formatType === 'ISO') {
    return {
      from: start.toISOString(),
      to: end.toISOString(),
    }
  } else {
    return {
      from: start.format('YYYY-MM-DD HH:mm:ss'),
      to: end.format('YYYY-MM-DD HH:mm:ss'),
    }
  }
}
export interface IDateSortItem {
  name: string
  value: any
}

export const sortTimeOptionList: IDateSortItem[] = [
  { name: 'Today', value: getDateRange('today') },
  { name: 'This week', value: getDateRange('this_week') },
  { name: 'Last week', value: getDateRange('last_week') },
  { name: 'This month', value: getDateRange('this_month') },
  { name: 'Last month', value: getDateRange('last_month') },
  { name: 'Last 3 month', value: getDateRange('last_3_month') },
  { name: 'Last year', value: getDateRange('last_year') },
]
export const sortTimeOptionList2: IDateSortItem[] = [
  { name: 'Today', value: getDateRange('today', 'Custom') },
  { name: 'This week', value: getDateRange('this_week', 'Custom') },
  { name: 'Last week', value: getDateRange('last_week', 'Custom') },
  { name: 'This month', value: getDateRange('this_month', 'Custom') },
  { name: 'Last month', value: getDateRange('last_month', 'Custom') },
  { name: 'Last 3 month', value: getDateRange('last_3_month', 'Custom') },
  { name: 'Last year', value: getDateRange('last_year', 'Custom') },
]
export const sortTimeOptionList3 = (startDate: string) => [
  { name: '1 Week', value: getDateRange2('1week', 'Custom', startDate) },
  { name: '1 Month', value: getDateRange2('1month', 'Custom', startDate) },
  { name: '3 Months', value: getDateRange2('3months', 'Custom', startDate) },
  { name: '6 Months', value: getDateRange2('6months', 'Custom', startDate) },
  { name: '1 Year', value: getDateRange2('1year', 'Custom', startDate) },
]

export const convertUTCTimeByTimeZone = (
  utcDateTime: any,
  timezone?: string
) => {
  if (!utcDateTime) return null

  // Base format for the date
  let formatString = 'DD MMM YYYY | hh:mm A'

  const timeZone = timezone ?? moment.tz.guess()
  const convertedDate = momentTimezone.utc(utcDateTime).tz(timeZone)
  return convertedDate?.format(formatWithTimezone(formatString))
}

// Function to return the formatted date with optional timezone
export const formatWithTimezone = (formatString: string): string => {
  // Append timezone if SHOW_TIMEZONE is true
  if (SHOW_TIMEZONE) {
    formatString += ' (z)'
  }
  return formatString
}

export const dateStringToUnix = (date: string) => {
  if (date) return moment(date).unix() * 1000
}

export const timestampToTimeRemaining = (timestamp: string = ''): string[] => {
  if (!timestamp) return []

  const currentMoment = moment().local()
  const futureMoment = moment(timestamp).local()
  const duration = moment.duration(futureMoment.diff(currentMoment))

  interface TimeUnit {
    unit: string
    value: number
  }

  const timeUnits: TimeUnit[] = [
    { unit: 'year', value: Math.floor(duration.asYears()) },
    { unit: 'month', value: Math.floor(duration.asMonths() % 12) },
    { unit: 'day', value: Math.floor(duration.asDays() % 7) },
    { unit: 'hour', value: Math.floor(duration.asHours() % 24) },
    { unit: 'minute', value: Math.floor(duration.asMinutes() % 60) },
    { unit: 'second', value: Math.floor(duration.asSeconds() % 60) },
  ]

  const formatUnit = (value: number, unit: string): string | null => {
    if (!value) return null

    const pluralizedUnit = value > 1 ? `${unit}s` : unit
    return `${value} ${pluralizedUnit}`
  }

  return timeUnits
    .map(({ unit, value }) => formatUnit(value, unit))
    .filter((unit): unit is string => Boolean(unit)) // Type narrowing to ensure it's a string
}

export function calculatePastTimeDateStamp(
  duration: moment.DurationInputArg1
): string {
  // Get the current time
  const currentTime = moment()

  // Subtract the specified duration from the current time
  const pastTime = currentTime.subtract(duration, 'hours')

  // Format the past time as a date stamp in the desired format
  const dateStamp = pastTime.format('YYYY-MM-DDTHH:mm:ss.SSSSSS')

  return dateStamp
}

export const getAuctionTimeText = (
  endTime: number,
  isStart?: boolean,
  isDutch?: boolean,
  dutchEndPrice?: string
) => {
  const timeFormat = moment(endTime * 1000).format('hh:mm a')
  const dateFormat = moment(endTime * 1000).format('MMM DD, YYYY')
  if (isStart) {
    return `Sale starts on ${dateFormat} at ${timeFormat}`
  } else if (isDutch) {
    return `Sale ends ${dateFormat} at ${timeFormat} at ${dutchEndPrice}`
  } else {
    return `Sale ends ${dateFormat} at ${timeFormat}`
  }
}

export const getLaunchpadTimeText = (
  endTime: number,
  isStart?: boolean
): string => {
  // Guess the user's local timezone
  const userTimezone = momentTimezone.tz.guess()

  // Convert endTime from seconds to milliseconds and adjust for timezone
  const endTimeMoment = momentTimezone.utc(endTime * 1000).tz(userTimezone)

  // Base format strings for time and date

  let dateFormat = 'MMM DD, YYYY [at] hh:mm a'

  // Use the separate function to append timezone if needed
  dateFormat = formatWithTimezone(dateFormat)

  // Format the time and date with or without the timezone
  const dateText = endTimeMoment.format(dateFormat)

  // Determine the message based on whether the launchpad has started or ended
  if (new Date() > new Date(endTime * 1000)) {
    return `Launchpad ended on ${dateText}`
  }

  if (isStart) {
    return `Launchpad starts on ${dateText}`
  } else {
    return `Launchpad ends on ${dateText}`
  }
}

export const isFutureDate = (date1: string, date2: string): boolean => {
  const dateTime1 = new Date(date1) // Convert date1 string to Date object
  const dateTime2 = new Date(date2) // Convert date2 string to Date object

  if (isNaN(dateTime1.getTime()) || isNaN(dateTime2.getTime())) {
    // Check if both dates are valid
    return false
  }

  // Check if date1 is in the future compared to date2
  return dateTime1 > dateTime2
}

export const isFutureDate2 = (date1: string, date2: string): boolean => {
  const dateTime1 = new Date(date1) // Convert date1 string to Date object
  const dateTime2 = new Date(date2) // Convert date2 string to Date object

  if (isNaN(dateTime1.getTime()) || isNaN(dateTime2.getTime())) {
    // Check if both dates are valid
    return false
  }

  // Check if date1 is in the future compared to date2
  return dateTime1 >= dateTime2
}

interface Countdown {
  days: number
  hours: number
  minutes: number
  seconds: number
}

export const calculateCountdown = (targetDate: string): Countdown => {
  const userTimezone = momentTimezone.tz.guess() // Detect the user's local timezone
  const endDate = momentTimezone.utc(targetDate).tz(userTimezone).toDate() // Convert UTC targetDate to local timezone
  const now = new Date()

  const totalSeconds = Math.floor((endDate.getTime() - now.getTime()) / 1000)

  if (totalSeconds <= 0) {
    return { days: 0, hours: 0, minutes: 0, seconds: 0 }
  }

  const days = Math.floor(totalSeconds / (3600 * 24))
  const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600)
  const minutes = Math.floor((totalSeconds % 3600) / 60)
  const seconds = totalSeconds % 60

  return { days, hours, minutes, seconds }
}
export const covertTimeByTimeZone = (date: string, timezone: string) => {
  const convertedDate = momentTimezone.tz(`${date}Z`, timezone)

  return {
    timestamp: convertedDate.valueOf(),
  }
}

export const convertSecondsToHours = (seconds: number): string => {
  const hours = seconds / 3600
  const hourLabel = hours === 1 ? 'hour' : 'hours'
  return `${hours} ${hourLabel}`
}
