import React from 'react'
import { TooltipContainer, TooltipText } from './style'
import { BoldText4 } from 'shared/typography'
import { Col, HorizontalLine } from 'shared/styled'
import { withTheme } from 'styled-components'
import { Theme } from 'styles/theme'

interface TooltipProps {
  text: string | React.ReactNode
  children?: React.ReactNode
  Icon?: any
  theme?: Theme
  heading?: string
  direction?: 'top' | 'bottom' | 'left' | 'right'
  variant?: 'default' | 'dark'
}

/**
 * Tooltip component that displays a text or React node as a tooltip.
 *
 * @param {string | React.ReactNode} text - The content to be displayed inside the tooltip.
 * @param {React.ReactNode} [children] - Optional children elements to be rendered alongside the tooltip.
 * @param {any} [Icon] - Optional icon component to be displayed within the tooltip.
 * @param {string} [heading] - Optional heading to be displayed on the tooltip content
 * @param {'top' | 'bottom' | 'left' | 'right'} [direction] - Optional direction for the tooltip's appearance.
 * @param {'default' | 'dark'} [variant] - Optional style variant for the tooltip.
 *
 * @returns {JSX.Element} The rendered Tooltip component.
 */

const Tooltip: React.FC<TooltipProps> = withTheme(
  ({
    text,
    children,
    Icon,
    direction,
    theme,
    heading,
    variant = 'default',
  }) => {
    return (
      <TooltipContainer>
        {children}
        {Icon}
        <TooltipText direction={direction} variant={variant}>
          {heading && (
            <Col width='200px' align='start' marginBottom='10px'>
              <BoldText4 color={theme.secondary}>{heading}</BoldText4>
              <HorizontalLine />
            </Col>
          )}

          {text}
        </TooltipText>
      </TooltipContainer>
    )
  }
)

export default Tooltip
