export const numberRegex = /^\d+(\.\d+)?$/
export const urlValidationRegEx =
  /^(https?:\/\/)?(www\.)?[a-z0-9-]+\.[a-z]{2,6}(\/[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+(&[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+)*)?$/
export const positiveIntNumRegex = /^(0|[1-9]\d*)$/
export const floatNumTwoDecimalsRegex = /^\d*(\.\d{0,2})?$/ // Check if input is a number with up to 2 decimal places (decimal number allowed)
export const emailRegex = /^[^\s@]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/ // Regex to validate email address
export const floatNumRegex = /^(?!.*\..*\.)[.\d]+$/ // check if input is number (decimal number allowed)
export const phoneNumberRegex = /^\+(?:\d ?){6,14}\d$/
export const protocolRegex = /^https?:\/\//i // check for "http://" or "https://"
export const alphabetRegex = /^[A-Za-z\s]+$/ // allows only alphabets (both uppercase and lowercase) and whitespace characters
export const intRegex = /^-?\d*$/ // allow integer
