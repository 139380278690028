import { Row } from 'shared/styled'
import styled from 'styled-components'

interface IBadge {
  bgColor?: string
  borderColor?: string
  solidBg?: boolean
}

export const Badge = styled.div<IBadge>`
  width: max-content;
  min-width: 80px;
  height: 25px;
  border: 1px solid ${(props) => props.borderColor ?? '#9163CC'};
  border-radius: 23px;
  background: ${(props) => props.bgColor ?? 'rgba(231, 231, 231, 0.36)'};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
`

export const StatusBadge = styled.div<IBadge>`
  width: max-content;
  min-width: 70px;
  padding: 4px 14px;
  height: 25px;
  border: 1px solid ${(props) => props.color ?? '#9163CC'};
  color: ${(props) => props.color ?? '#9163CC'};
  border-radius: 23px;
  background: ${(props) =>
    props.color ? `${props.color}10` : 'rgba(231, 231, 231, 0.36)'};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.4px;
  text-transform: uppercase;
`

export const CategoryBadgeWrapper = styled.div<IBadge>`
  border-radius: 23px;
  background: ${(props) =>
    props.bgColor ?? 'linear-gradient(38deg, #acbb78 4.26%, #f7f8f8 94.97%'};
  border: 1px solid ${(props) => props.theme.primaryText};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  color: ${(props) => props.theme.primaryText};
  height: 26px;

  p {
    font-style: italic;
    font-weight: 600;
  }
`

export const UserRoleBadgeWrapper = styled.div<{ color?: string }>`
  display: flex;
  padding: 3px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: ${(props: any) => props.theme.secondary};
  background: ${(props: any) =>
    props.color ? props.color : props.theme.green};
  border-radius: 50px;
  font-family: Poppins;
  font-size: 7px;
  font-style: italic;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.2px;
  text-wrap: nowrap;
`
export const ChipWrapper = styled.div<IBadge>`
  border-radius: 23px;
  background: ${(props) =>
    props.bgColor ?? 'linear-gradient(38deg, #acbb78 4.26%, #f7f8f8 94.97%'};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  color: ${(props) => props.theme.secondary};
  height: 22px;

  p {
    color: inherit !important;
    font-weight: 500;
  }
`

export const TagWrapper = styled(Row)<IBadge>`
  gap: 4px;
  border-radius: 23px;
  background: ${(props) =>
    props.solidBg
      ? props.bgColor ?? '#f2f2f2'
      : `${props.bgColor ?? '#f2f2f2'}20`};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 8px;

  height: 24px;
  width: max-content;
  p {
    text-wrap: nowrap;
    color: ${(props) =>
      props.solidBg ? '#5A6376' : props.bgColor ?? '#5A6376'};
  }
`

export const Dot = styled.div<IBadge>`
  background: ${(props) =>
    props.solidBg ? '#5A6376' : props.bgColor ?? '#687182'};
  height: 6px;
  width: 6px;
  border-radius: 2px;
`
