import {
  addValueToParameterPath,
  addNewEntitiesPath,
  categorySettingsPath,
  configurationPath,
  createBlogsPath,
  createEducationPath,
  createFAQPath,
  createFunctionalityPath,
  createGlossaryPath,
  createGroupPath,
  createKBAPath,
  createParameterPath,
  createNewSubscriptionPath,
  createTermsPath,
  EditAccountDeleteContentPath,
  editAddValueToParameterPath,
  editBlogsPath,
  editCategoryPagePath,
  editEducationPath,
  editFAQPagePath,
  editFunctionalityPath,
  editGlossaryPagePath,
  editGroupPath,
  editKBAPagePath,
  editParameterPath,
  editSubscriptionPath,
  editTermsPath,
  newCategoryPath,
  parameterDetailsPath,
  signInPath,
  subscriptionPlanDetailPath,
  newEducationalCategoryPath,
  editEducationalCategoryPagePath,
  AddValueToParameterPathWithParameter,
} from 'logic/paths'
import React from 'react'

const CategorySettings = React.lazy(() =>
  import('modules/pages/categorySettings').then((module) => ({
    default: module.CategorySettings,
  }))
)
const ConfigurationPage = React.lazy(() =>
  import('modules/pages/configurations').then((module) => ({
    default: module.ConfigurationPage,
  }))
)
const CreateNewCategory = React.lazy(() =>
  import('modules/pages/createCategory').then((module) => ({
    default: module.CreateNewCategory,
  }))
)
const CreateNewEducationalCategory = React.lazy(() =>
  import('modules/pages/createCategory/CreateNewEducationalCategory').then(
    (module) => ({
      default: module.CreateNewEducationalCategory,
    })
  )
)
const CreateNewFaq = React.lazy(() =>
  import('modules/pages/createFaq').then((module) => ({
    default: module.CreateNewFaq,
  }))
)
const CreateNewGlossary = React.lazy(() =>
  import('modules/pages/createGlossary').then((module) => ({
    default: module.CreateNewGlossary,
  }))
)
const CreateTermsPage = React.lazy(() =>
  import('modules/pages/createTerms').then((module) => ({
    default: module.CreateTermsPage,
  }))
)
const EditBlogPage = React.lazy(() =>
  import('modules/pages/editBlogs').then((module) => ({
    default: module.EditBlogPage,
  }))
)

const EditEducationPage = React.lazy(() =>
  import('modules/pages/editEducationPage').then((module) => ({
    default: module.EditEducationPage,
  }))
)

const EditCategoryPage = React.lazy(() =>
  import('modules/pages/editCategory').then((module) => ({
    default: module.EditCategoryPage,
  }))
)
const EditFaq = React.lazy(() =>
  import('modules/pages/editFaq').then((module) => ({
    default: module.EditFaq,
  }))
)

const EditSecurityQuestions = React.lazy(() =>
  import('modules/pages/editSecurityQuestions').then((module) => ({
    default: module.EditSecurityQuestions,
  }))
)

const EditAccountDeleteContent = React.lazy(() =>
  import('modules/pages/editAccountDeleteContent').then((module) => ({
    default: module.EditAccountDeleteContent,
  }))
)
const CreateFunctionality = React.lazy(() =>
  import(
    'modules/pages/systemConfigurator/createFunctionality/CreateFunctionality'
  ).then((module) => ({
    default: module.CreateFunctionality,
  }))
)
const CreateParameter = React.lazy(() =>
  import(
    'modules/pages/systemConfigurator/createParameter/CreateParameter'
  ).then((module) => ({
    default: module.CreateParameter,
  }))
)
const ParameterDetailsPage = React.lazy(() =>
  import(
    'modules/pages/systemConfigurator/parameterDetailsPage/ParameterDetailsPage'
  ).then((module) => ({
    default: module.ParameterDetailsPage,
  }))
)
const AddValueToParameter = React.lazy(() =>
  import(
    'modules/pages/systemConfigurator/addValueToParameter/AddValueToParameter'
  ).then((module) => ({
    default: module.AddValueToParameter,
  }))
)

const CreateGroup = React.lazy(() =>
  import('modules/pages/systemConfigurator/createGroup/CreateGroup').then(
    (module) => ({
      default: module.CreateGroup,
    })
  )
)

const CreateNewSubscription = React.lazy(() =>
  import(
    'modules/pages/subscriptionPlan/components/CreateNewSubscription'
  ).then((module) => ({
    default: module.CreateNewSubscription,
  }))
)
const SubscriptionDetail = React.lazy(() =>
  import('modules/pages/subscriptionDetail/SubscriptionDetail').then(
    (module) => ({
      default: module.SubscritptionDetail,
    })
  )
)
const AddNewEntities = React.lazy(() =>
  import('modules/pages/subscriptionDetail/components/AddNewEntities').then(
    (module) => ({
      default: module.AddEntitiesToTier,
    })
  )
)

export const configureAdminRoutes = [
  {
    path: configurationPath,
    element: ConfigurationPage,
    protected: true,
    title: 'Configurations',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: newCategoryPath,
    element: CreateNewCategory,
    protected: true,
    title: 'Create Category',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: newEducationalCategoryPath,
    element: CreateNewEducationalCategory,
    protected: true,
    title: 'Create Category',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: editEducationalCategoryPagePath,
    element: CreateNewEducationalCategory,
    protected: true,
    title: 'Create Category',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: editCategoryPagePath,
    element: EditCategoryPage,
    protected: true,
    title: 'Edit Category',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: categorySettingsPath,
    element: CategorySettings,
    protected: true,
    title: 'Configure Categories',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: createFAQPath,
    element: CreateNewFaq,
    protected: true,
    title: 'New FAQ',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: editFAQPagePath,
    element: EditFaq,
    protected: true,
    title: 'Edit FAQ',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: editKBAPagePath,
    element: EditSecurityQuestions,
    protected: true,
    title: 'Create KBA',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: createKBAPath,
    element: EditSecurityQuestions,
    protected: true,
    title: 'Edit KBA',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: createBlogsPath,
    element: EditBlogPage,
    protected: true,
    title: 'Create Blog',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: createEducationPath,
    element: EditEducationPage,
    protected: true,
    title: 'Create Education',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: editBlogsPath,
    element: EditBlogPage,
    protected: true,
    title: 'Edit Blog',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: editEducationPath,
    element: EditEducationPage,
    protected: true,
    title: 'Edit Blog',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: createGlossaryPath,
    element: CreateNewGlossary,
    protected: true,
    title: 'Create Glossary',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: editGlossaryPagePath,
    element: CreateNewGlossary,
    protected: true,
    title: 'Edit Glossary',
    pathType: 0,
    redirect: signInPath,
  },

  {
    path: createTermsPath,
    element: CreateTermsPage,
    protected: true,
    title: 'Create T&C',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: editTermsPath,
    element: CreateTermsPage,
    protected: true,
    title: 'Update T&C',
    pathType: 0,
    redirect: signInPath,
  },

  {
    path: EditAccountDeleteContentPath,
    element: EditAccountDeleteContent,
    protected: true,
    title: 'Edit account deletion content',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: createFunctionalityPath,
    element: CreateFunctionality,
    protected: true,
    title: 'Create new functionality',

    pathType: 0,
    redirect: signInPath,
  },
  {
    path: editFunctionalityPath,
    element: CreateFunctionality,
    protected: true,
    title: 'Edit functionality',
    pathType: 0,
    redirect: signInPath,
  },

  {
    path: createParameterPath,
    element: CreateParameter,
    protected: true,
    title: 'Create new parameter',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: subscriptionPlanDetailPath,
    element: SubscriptionDetail,
    protected: true,
    title: 'Subscription Detail',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: editParameterPath,
    element: CreateParameter,
    protected: true,
    title: 'Edit parameter',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: parameterDetailsPath,
    element: ParameterDetailsPage,
    protected: true,
    title: 'Parameter details page',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: addValueToParameterPath,
    element: AddValueToParameter,
    protected: true,
    title: 'Add value to parameter',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: editAddValueToParameterPath,
    element: AddValueToParameter,
    protected: true,
    title: 'Add value to parameter',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: AddValueToParameterPathWithParameter,
    element: AddValueToParameter,
    protected: true,
    title: 'Add value to parameter',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: createGroupPath,
    element: CreateGroup,
    protected: true,
    title: 'Create new group',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: editGroupPath,
    element: CreateGroup,
    protected: true,
    title: 'edit group',

    pathType: 0,
    redirect: signInPath,
  },
  {
    path: createNewSubscriptionPath,
    element: CreateNewSubscription,
    protected: true,
    title: 'Create new subscription',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: addNewEntitiesPath,
    element: AddNewEntities,
    protected: true,
    title: 'Add New Entities',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: editSubscriptionPath,
    element: CreateNewSubscription,
    protected: true,
    title: 'Edit subscription',
    pathType: 0,
    redirect: signInPath,
  },
]
