import { Reorder } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { Button } from 'shared/button'
import { Col, Row } from 'shared/styled'
import styled from 'styled-components'
import { ReactComponent as ReorderDotsIcon } from 'assets/icons/reorder-dots.svg'
import { ITableColumnConfig } from 'utils/type' // Import the correct type for columns

interface ICustomColumnModalProps {
  columns: ITableColumnConfig[]
  setTableHead: (columns: ITableColumnConfig[]) => void
  tableColumns: string[]
  setTableColumns: (columns: string[]) => void
  setTableColumn: (columns: ITableColumnConfig[]) => void
  handleClose: () => void
}

export const CustomColumnModal: React.FC<ICustomColumnModalProps> = ({
  columns,
  setTableHead,
  tableColumns,
  setTableColumns,
  handleClose,
  setTableColumn,
}) => {
  const [items, setItems] = useState<ITableColumnConfig[]>(columns)
  const [updatedOrder, setUpdatedOrder] = useState<ITableColumnConfig[]>([])

  useEffect(() => {
    if (columns?.length) setItems(columns)
  }, [columns])

  const handleSetItems = (newItems: ITableColumnConfig[]) => {
    setItems(newItems)
    const newOrder = calculateUpdatedOrder(newItems)
    setUpdatedOrder(newOrder)
    setTableColumns(newOrder.map((item) => item.dataKey)) // Update tableColumns in the correct order
  }

  const handleCustomizeColumns = () => {
    setTableHead(updatedOrder)
    setTableColumn(updatedOrder)

    const orderedCheckedColumns = updatedOrder.map((item) => item.dataKey)
    setTableColumns(orderedCheckedColumns) // Ensure main component receives updated order

    handleClose()
  }

  const calculateUpdatedOrder = (
    arr: ITableColumnConfig[]
  ): ITableColumnConfig[] => {
    if (!arr) return []
    return arr.map((el, index) => ({
      ...el,
      accessor: `col${index + 1}`,
    }))
  }

  return (
    <Container>
      <Reorder.Group values={items} onReorder={handleSetItems}>
        {items.map((item) => (
          <Reorder.Item key={item.accessor} value={item}>
            <FilterItem align='center'>
              <RenderColumn>
                <Row>{item.Header ? <item.Header /> : null}</Row>
              </RenderColumn>
              <ReorderDotsIcon />
            </FilterItem>
          </Reorder.Item>
        ))}
      </Reorder.Group>

      <Row
        gap='16px'
        marginTop='20px'
        justify='center'
        align='center'
        width='100%'
      >
        <Button
          type='button'
          btnType='secondary'
          width='259px'
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          isDisabled={!updatedOrder.length}
          type='submit'
          btnType='primary'
          width='259px'
          onClick={handleCustomizeColumns}
        >
          Apply
        </Button>
      </Row>
    </Container>
  )
}

const Container = styled(Col)`
  max-width: 578px;
  align-items: center;
  border-top: 1px solid ${(props) => props.theme.borderColor};
  margin-top: 10px;
  ul {
    width: 100%;
    list-style-type: none;
    height: 500px;
    overflow: auto;

    li {
      margin: 6px 0;
    }
  }
`

const FilterItem = styled(Row)`
  width: 100%;
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.borderColor};
  height: 50px;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  cursor: grab;
`

const RenderColumn = styled(Row)`
  align-items: center;
  position: relative;

  svg {
    display: none;
  }

  .checkbox {
    position: absolute;
    top: -7px;
  }
`
