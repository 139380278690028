import axios from 'axios'
import { PAGE_LIMIT } from 'modules/pages/gamePlayers/constants'
import toast from 'react-hot-toast'
import { axiosClient } from './axios'
import { serverConfig } from './constants'
import { mediaTypes } from './enums'
import { getPresignedUrlContentType } from './helpers/commonHelpers'
import { getOptimizedImage } from './helpers/fileHelpers'
import { IAdminProfile, IAllQuestAPIFilters, IQuest } from './type'
export const handleError = (error: any) => {
  if (!error?.response) {
    toast.error('Something went wrong')
    return
  }

  let logoutEvent401: any = ''

  switch (error.response.status) {
    case 401:
      logoutEvent401 = new CustomEvent('logoutEvent')
      window.dispatchEvent(logoutEvent401)
      break
    case 403:
      toast.error(error.response.data.message)
      setTimeout(() => {
        const logoutEvent403 = new CustomEvent('logoutEvent')
        window.dispatchEvent(logoutEvent403)
      }, 3000)
      break
    case 413:
      toast.error('The content is too large.')
      break
    default:
      if (error.response.data?.message) {
        toast.error(error.response.data.message)
      } else {
        toast.error('Something went wrong')
      }
      break
  }
}

export const GetAvailableName = async (name: string) => {
  try {
    const url = serverConfig.baseURL + serverConfig.getAvailableName
    const res = await axiosClient.get(url, { params: { game_name: name } })
    return res.data
  } catch (error: any) {
    handleError(error)
    return false
  }
}

export const GetCryptoPriceRates = async () => {
  try {
    const url = serverConfig.baseURL + serverConfig.cryptoRates
    const res = await axiosClient.get(url)
    return res?.data
  } catch (error: any) {
    console.error('error..', error)
  }
}

const GetPresignedUrl = async (media_type?: string, content_type?: string) => {
  const getPresignedS3Url = serverConfig.baseURL + serverConfig.getPreSignedS3

  const params = {
    media_type,
    content_type: getPresignedUrlContentType(content_type),
  }
  return axiosClient.get(getPresignedS3Url, { params })
}
export const UploadImageToS3 = async (
  file: File,
  setProgress: (n: number) => void,
  media_type?: mediaTypes
): Promise<string> => {
  try {
    // Get pre-signed URL from the server
    const response = await GetPresignedUrl(media_type, file.type)
    const link = response.data.url
    const xhr = new XMLHttpRequest()

    let fileToBeUploaded: File = file

    // Optimize the image if it is an image file
    if (file.type.startsWith('image/')) {
      //@ts-ignore
      fileToBeUploaded = await getOptimizedImage(file)
    }

    // Return a promise that resolves when the upload is complete
    return new Promise<string>((resolve, reject) => {
      xhr.open('PUT', link, true)
      xhr.setRequestHeader('Content-Type', fileToBeUploaded.type)
      xhr.setRequestHeader('x-amz-acl', 'public-read')

      // Track upload progress
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded / event.total) * 100)
          setProgress(progress)
        }
      }

      // Handle upload completion
      xhr.onload = () => {
        if (xhr.status === 200) {
          setProgress(100) // Ensure progress reaches 100% on completion
          resolve(link.split('?')[0]) // Resolve the promise with the file URL
        } else {
          console.error('Upload failed with status:', xhr.status)
          reject(new Error(`Upload failed with status: ${xhr.status}`))
        }
      }

      // Handle upload errors
      xhr.onerror = (error) => {
        console.error('Error in upload:', error)
        reject(error)
      }

      // Send the file to the server
      xhr.send(fileToBeUploaded)
    })
  } catch (error) {
    console.error('Error in uploading to S3', error)
    throw error
  }
}

export const UploadQuestImageToS3 = async (
  file: any,
  setProgress: (n: number) => void,
  media_type?: mediaTypes
) => {
  try {
    const response = await GetQuestPresignedUrl(media_type, file.type)

    let fileToBeUploaded: File = file

    // Optimize the image if it is an image file
    if (file.type.startsWith('image/')) {
      //@ts-ignore
      fileToBeUploaded = await getOptimizedImage(file)
    }

    const link = response.data.url
    let myHeaders = new Headers()
    myHeaders.append('Content-Type', fileToBeUploaded.type)
    myHeaders.append('x-amz-acl', 'public-read')

    let requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: fileToBeUploaded,
    }

    await fetch(link, requestOptions)

    return link?.split('?')[0]
  } catch (error) {
    console.error('error in uploading ot s3', error)
    throw error
  }
}

export const fetchChainPrice = async (chain: string) => {
  try {
    const url = serverConfig.baseURL + serverConfig.cryptoCurrency
    const response = await axiosClient.get(url)
    return response?.data
  } catch (error) {
    console.error(error)
  }
}

export const GetQuestPresignedUrl = async (
  media_type?: string,
  content_type?: string
) => {
  const getPresignedS3Url =
    serverConfig.questBaseURL + serverConfig.getPreSignedS3Quest

  const params = {
    media_type,
    content_type: getPresignedUrlContentType(content_type),
  }
  return axiosClient.get(getPresignedS3Url, { params })
}

export const GetCompanies = async (filters: any) => {
  const url = serverConfig.baseURL + serverConfig.getCompanies

  try {
    const res = await axiosClient.get(url, { params: { ...filters } })
    if (res) return res?.data
  } catch (error) {
    handleError(error)
  }
}
export const GetCompanyPublishers = async (filters: any) => {
  const url = serverConfig.baseURL + serverConfig.getCompanyPublishers

  try {
    const res = await axiosClient.get(url, { params: { ...filters } })
    if (res) return res?.data
  } catch (error) {
    handleError(error)
  }
}
export const GetCompanyRequests = async (filters: any) => {
  const url = serverConfig.baseURL + serverConfig.getCompanyRequest

  try {
    const res = await axiosClient.get(url, { params: filters })

    if (res) return res?.data
  } catch (error) {
    handleError(error)
  }
}

export const GetCompanyDataChanges = async (companyId: string) => {
  const url =
    serverConfig.baseURL +
    serverConfig.getCompanyRequestChanges +
    '/' +
    companyId

  try {
    const res = await axiosClient.get(url)
    if (res) return res?.data
  } catch (error) {
    handleError(error)
  }
}

export const GetAllQuest = async (
  page: number = 1,
  limit: number = PAGE_LIMIT,
  filters?: IAllQuestAPIFilters
): Promise<{
  count: number
  data: IQuest[]
}> => {
  try {
    const getAllQuest = serverConfig.questBaseURL + serverConfig.getAllQuest

    const { data } = await axios.post(`${getAllQuest}`, {
      ...filters,
      page,
      limit,
    })
    return {
      count: data?.total,
      data: data?.data,
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const GetAdminsList = async (
  page: number = 1,
  limit: number = PAGE_LIMIT,
  role?: string,
  search?: string | null
): Promise<{
  result: IAdminProfile[]
  count: number
  remaining_pages: number
}> => {
  try {
    const adminList = serverConfig.baseURL + serverConfig.getAllAdminSubAdmins

    const { data } = await axiosClient.get(`${adminList}`, {
      params: {
        page,
        limit,
        role,
        search,
      },
    })
    return {
      count: data?.total,
      result: data?.data,
      remaining_pages: data?.remaining_pages,
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}
