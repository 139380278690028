import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding-top: 28px;
  padding-bottom: 96px;
  svg:hover {
    cursor: pointer;
  }
`
