import styled from 'styled-components'
import { screenSizes } from 'styles/theme'

export const TableContainer = styled.div`
  margin-top: 10px;
  border-radius: 6px;
  border: 1px solid rgb(0 0 0 / 10%);
  width: 100%;
  overflow: auto;
  .viewDetailsText {
    :hover {
      cursor: pointer;
    }
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }
  thead {
    tr {
      border-radius: 5px;
      background: ${(props) => props.theme.borderColor};
    }
    th {
      padding: 0 10px;
      height: 54px;
    }
  }
  table th:first-child {
    border-radius: 5px 0 0 5px;
  }

  table th:last-child {
    border-radius: 0 5px 5px 0;
  }
  tbody {
    tr {
      border-bottom: 1px solid ${(props) => props.theme.borderColor};
      transition: 0.2s linear;
      :hover {
        background-color: #e7e7e7;
      }
    }
    td {
      padding: 10px;
      border-right: 1px solid ${(props) => props.theme.borderColor};
    }

    .subTableContainer {
      padding: 0;
    }
  }
  .copyIcon:hover {
    transform: scale(1.2);
    transition: all 0.1s ease-in;
    cursor: pointer;
  }

  .amountDetails {
    P {
      white-space: nowrap;
    }
  }
`

export const TableData = styled.td`
  @media (min-width: ${screenSizes.mediaXXL}px) {
    p {
      font-size: 14px;
    }
  }
`

export const SubTable = styled.table`
  thead {
    th {
      padding: 0 10px;
      height: 38px !important;
    }
  }
`
