import Coinbase from 'assets/icons/coinbase-wallet.svg'
import Metamask from 'assets/icons/metamask-wallet.svg'
import Phantom from 'assets/icons/phantom-wallet.svg'
import DiscordLogo from 'assets/icons/socialLinks/discord.svg'
import GoogleLogo from 'assets/icons/socialLinks/google.svg'
import InstagramLogo from 'assets/icons/socialLinks/instagram.svg'
import MetaLogo from 'assets/icons/socialLinks/meta.svg'
import TwitterLogo from 'assets/icons/socialLinks/twitter.svg'
import WalletConnect from 'assets/icons/wallet-connect.svg'
import { capitalizeFirstLetter } from 'utils/helpers/stringHelpers'
import { SOCIAL_MEDIA, USER_TYPE, WALLET_TYPE } from 'utils/enums'
import {
  CheckboxFilterConfig,
  DateRangeFilterConfig,
  DropdownFilterConfig,
  InputFilterConfig,
  RadioFilterConfig,
} from 'utils/reportType'

interface StaticFilterConfig {
  type: ''
  label: string
  options: string[]
  dataKey?: string
  disabled?: boolean
}

interface RangeFilterConfig {
  type: 'range'
  label: string
  min: number
  max: number
  step?: number
  unit?: string
  isFloor?: boolean
  dataKey?: string
  disabled?: boolean
}

export type FilterConfig =
  | StaticFilterConfig
  | RangeFilterConfig
  | DropdownFilterConfig
  | CheckboxFilterConfig
  | DateRangeFilterConfig
  | RadioFilterConfig
  | InputFilterConfig

export const filters: FilterConfig[] = [
  {
    type: 'dropdown',
    headerText: 'Company ID',
    enableSearch: true,
    options: [],
    label: 'Company ID',
    dataKey: 'company_id',
  },
  {
    type: '',
    label: 'First Name',
    options: [],
    dataKey: 'first_name',
  },
  {
    type: '',
    label: 'Last Name',
    options: [],
    dataKey: 'last_name',
  },
  {
    type: '',
    label: 'Display Name',
    options: [],
    dataKey: 'display_name',
  },
  {
    type: '',
    label: 'Email',
    options: [],
    dataKey: 'email',
  },
  {
    type: 'checkbox',
    label: 'Timezone',
    options: [],
    dataKey: 'timezone',
  },
  {
    type: 'range',
    label: 'Following',
    min: 0,
    max: 1000,
    step: 1,
    isFloor: true,
    dataKey: 'following',
  },
  {
    type: 'range',
    label: 'Followers',
    min: 0,
    max: 1000,
    step: 1,
    isFloor: true,
    dataKey: 'followers',
  },

  {
    type: 'input',
    label: 'Wallet Address(es)',
    options: [],
    dataKey: 'wallets',
  },
  {
    type: '',
    label: 'Connected Wallets',
    options: [],
    dataKey: 'active_connected_wallets',
  },

  {
    type: 'checkbox',
    label: 'Wallet Types',
    options: [
      {
        name: 'Phantom',
        value: WALLET_TYPE.PHANTOM,
        url: Phantom,
      },
      {
        name: 'Metamask',
        value: WALLET_TYPE.META_MASK,
        url: Metamask,
      },
      {
        name: 'Coinbase',
        value: WALLET_TYPE.COIN_BASE,
        url: Coinbase,
      },
      {
        name: 'Wallet Connect',
        value: WALLET_TYPE.WALLET_CONNECT,
        url: WalletConnect,
      },
    ],
    dataKey: 'wallet_type',
  },
  {
    type: '',
    label: '2FA',
    options: [],
    dataKey: 'fa_2_enabled',
  },
  {
    type: '',
    label: 'Quest Status',
    options: [],
    dataKey: 'quest_status',
  },
  // {
  //   type: '',
  //   label: 'Electronic Communications',
  //   options: [],
  //   dataKey: 'electronic_communication',
  // },
  {
    type: 'radio',
    label: 'Currency Type',
    options: [
      { name: 'ETH', value: 'ETH' },
      { name: 'SOL', value: 'SOL' },
      { name: 'USDC', value: 'USDC' },
    ],
    dataKey: 'currency_type',
  },
  {
    type: 'range',
    label: 'Sales',
    min: 0,
    max: 1000,
    step: 1,
    unit: 'units',
    dataKey: 'sales',
  },
  {
    type: 'range',
    label: 'Purchase',
    min: 0,
    max: 1000,
    step: 1,
    unit: 'units',
    dataKey: 'purchase',
  },
  {
    type: 'range',
    label: 'Listing Value',
    min: 0,
    max: 1000,
    step: 1,
    unit: 'units',
    dataKey: 'listing_value',
  },
  {
    type: 'range',
    label: 'Listing Quantity',
    min: 0,
    max: 1000,
    step: 1,
    isFloor: true,
    dataKey: 'listing_qty',
  },
  {
    type: 'range',
    label: 'Total Bids',
    min: 0,
    max: 1000,
    step: 1,
    isFloor: true,
    dataKey: 'total_bid',
  },
  {
    type: 'range',
    label: 'Successful Bids',
    min: 0,
    max: 1000,
    step: 1,
    unit: 'units',
    dataKey: 'successful_bid',
  },
  {
    type: 'range',
    label: 'Unsuccessful Bids',
    min: 0,
    max: 1000,
    step: 1,
    unit: 'units',
    dataKey: 'unsuccessful_bid',
  },
  {
    type: 'checkbox',
    label: 'Social Links',
    options: [
      {
        name: 'Meta',
        value: SOCIAL_MEDIA.META,
        url: MetaLogo,
      },
      {
        name: 'Google',
        value: SOCIAL_MEDIA.GOOGLE,
        url: GoogleLogo,
      },
      { name: 'Instagram', value: SOCIAL_MEDIA.INSTAGRAM, url: InstagramLogo },
      { name: 'X', value: SOCIAL_MEDIA.TWITTER, url: TwitterLogo },
      { name: 'Discord', value: SOCIAL_MEDIA.DISCORD, url: DiscordLogo },
    ],
    dataKey: 'social_link',
  },
  {
    type: 'dropdown',
    headerText: 'Interested Categories',
    enableSearch: true,
    options: [{ name: '', slug: '', checked: false }],
    label: 'Interested Categories',
    dataKey: 'interested_category',
  },
  {
    type: 'range',
    label: 'Items in cart',
    min: 0,
    max: 1000,
    step: 1,
    dataKey: 'items_in_cart',
  },
  {
    type: 'dateRange',
    disabled: true,
    label: 'Last Login',
    options: ['1D', '1W', '1M', 'Custom'],
    dataKey: 'last_login',
  },
  // {
  //   type: 'dateRange',
  //   label: 'Custom Date Range',
  //   options: ['1D', '1W', '1M', 'Custom'],
  //   dataKey: 'custom_date_range',
  // },
  // {
  //   type: 'range',
  //   label: 'Liked Games',
  //   min: 0,
  //   max: 1000,
  //   step: 1,
  //   dataKey: 'liked_games',
  // },
  {
    type: 'range',
    label: 'Liked NFTs',
    min: 0,
    max: 1000,
    step: 1,
    isFloor: true,
    dataKey: 'liked_assets',
  },
  // {
  //   type: 'checkbox',
  //   label: 'Liked Collections',
  //   options: [],
  //   dataKey: 'liked_collection',
  // },
  {
    type: 'input',
    label: 'Session Ids',
    options: [],
    dataKey: 'session_id',
  },
  {
    type: '',
    label: 'Session Count',
    options: [],
    dataKey: 'session_count',
  },
  // {
  //   type: 'checkbox',
  //   label: 'Session Count',
  //   options: [],
  //   dataKey: 'session_count',
  // },
  {
    type: 'checkbox',
    label: 'User Type',
    options: [
      {
        name: capitalizeFirstLetter(USER_TYPE.PLAYER),
        value: 4,
      },
      {
        name: capitalizeFirstLetter(USER_TYPE.PUBLISHER),
        value: 3,
      },
    ],
    dataKey: 'user_type',
  },

  {
    type: 'range',
    label: 'Quest in Progress',
    min: 0,
    max: 1000,
    step: 1,
    isFloor: true,
    dataKey: 'quest_in_progress',
  },
  {
    type: 'range',
    label: 'Quest Completed',
    min: 0,
    max: 1000,
    step: 1,
    isFloor: true,
    dataKey: 'quest_completed',
  },
  {
    type: 'range',
    label: 'Quest % Completed',
    min: 0,
    max: 100,
    step: 1,
    isFloor: true,
    dataKey: 'quest_per_completed',
  },
  {
    type: 'checkbox',
    label: 'Game Developer',
    options: [],
    dataKey: 'game_developer',
  },
  {
    type: 'range',
    label: 'Games Owned',
    min: 0,
    max: 1000,
    step: 1,
    isFloor: true,
    dataKey: 'games_owned',
  },

  {
    type: 'input',
    label: 'User Agent',
    options: [],
    dataKey: 'user_agent',
  },
  // {
  //   type: 'input',
  //   label: 'Device Id',
  //   options: [],
  //   dataKey: 'device_id',
  // },
  {
    type: 'input',
    label: 'Ipv4',
    options: [],
    dataKey: 'ipv4',
  },
  {
    type: 'input',
    label: 'Ipv6',
    options: [],
    dataKey: 'ipv6',
  },

  {
    type: 'range',
    label: 'Gas Cost',
    min: 0,
    max: 1000,
    step: 1,
    unit: 'units',
    dataKey: 'gas_cost',
  },
  {
    type: 'range',
    label: 'Gas Fee',
    min: 0,
    max: 1000,
    step: 1,
    unit: 'units',
    dataKey: 'gas_fee',
  },
  {
    type: 'range',
    label: 'Gas Unit',
    min: 0,
    max: 1000,
    step: 1,
    unit: 'units',
    dataKey: 'gas_unit',
  },
  {
    type: 'radio',
    label: 'Account Deletion Request',
    options: [
      { name: 'Yes', value: true },
      { name: 'NO', value: false },
    ],
    dataKey: 'account_deletion_request',
  },
  {
    type: 'checkbox',
    label: 'User Status',
    options: [
      { name: 'Active', value: 'Active' },
      { name: 'Deleted', value: 'Deleted' },
    ],
    dataKey: 'user_status',
  },
]
