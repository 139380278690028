import { useEffect } from 'react'

export const useClickOutside = (
  ref: any,
  setState: React.Dispatch<React.SetStateAction<boolean>>,
  update?: any
) => {
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setState(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, update]) // eslint-disable-line react-hooks/exhaustive-deps
}
