import styled from 'styled-components'

export const SearchBarContainer = styled.div<any>`
  width: 100%;
  display: flex;
  height: 41px;
  align-items: center;
  border: 1px solid ${(props) => props.theme.borderColor};
  overflow: hidden;
  border-radius: ${(props) => props?.borderRadius ?? `50px`};
  padding-right: 18px;
  padding-left: 20px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: ${(props) => props.theme.secondary};

  .input {
    width: 100%;
    border: none;
    height: 38px;
    border: none;

    :focus {
      outline: none;
    }
  }

  :focus-within {
    border: 1px solid ${(props) => props.theme.textColor3};
  }
`
