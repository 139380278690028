import styled, { css, keyframes } from 'styled-components'
import { globalPadding, maxWidth, screenSizes } from 'styles/theme'
import { IChip, IColProps, ILineProps, ISkeletonProps } from 'utils/styleTypes'
import { ILoaderProps } from 'utils/type'

interface IStyledTextarea {
  outlined?: boolean
  borderColor?: string
  fontWeight?: string
  accentColor?: string
  error?: boolean
  visited?: boolean
  borderRadius?: string
}
interface ICircularIconButton {
  padding?: string
  hideShadow?: boolean
  opacity?: string
}
interface ISimpleIconProps {
  height?: string
  width?: string
}

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border-left: none;
  width: 30px;
  height: 30px;
  background: rgb(195 157 242);
  :hover {
    border: 1px solid ${(props) => props.theme.primary};
    cursor: pointer;
  }
`

export const Col = styled.div<IColProps>`
  display: flex;
  flex-direction: column;
  gap: ${(props: IColProps) => props.gap ?? '6px'};
  max-width: ${(props: IColProps) => props.maxWidth};
  align-items: ${(props: IColProps) => props.align};
  justify-content: ${(props: IColProps) => props.justify};
  text-align: ${(props: IColProps) => props.textAlign};
  margin: ${(props: IColProps) => props.margin};
  margin-left: ${(props: IColProps) => props.marginLeft};
  margin-right: ${(props: IColProps) => props.marginRight};
  margin-top: ${(props: IColProps) => props.marginTop};
  margin-bottom: ${(props: IColProps) => props.marginBottom};
  padding: ${(props: IColProps) => props.padding};
  width: ${(props) => props.width};
  order: ${(props) => props.order};
  align-self: ${(props) => props.self};
  border: ${(props) => props.border};
  height: ${(props) => props.height};
  position: ${(props) => props.position};
  min-width: ${(props) => props.minWidth};
  overflow: ${(props) => props.overflow};
  border-radius: ${(props) => props?.borderRadius};
  max-height: ${(props) => props?.maxHeight};
`

export const Chip = styled.div<IChip>`
  display: inline-flex;
  background-color: ${(props) => props.background ?? props.theme.hyperLink};
  border-radius: 30px;
  padding: ${(props) => props.padding ?? `8px 30px`};
  border: ${(props) =>
    props.borderColor ? `1px solid ${props.borderColor}` : ``};
`

export const Row = styled.div<IColProps>`
  display: ${(props) => props?.display ?? 'flex'};
  gap: ${(props: IColProps) => props.gap ?? '10px'};
  align-items: ${(props: IColProps) => props.align};
  justify-content: ${(props: IColProps) => props.justify};
  width: ${(props: IColProps) => props.width};
  margin: ${(props: IColProps) => props.margin};
  margin-left: ${(props: IColProps) => props.marginLeft};
  margin-right: ${(props: IColProps) => props.marginRight};
  margin-top: ${(props: IColProps) => props.marginTop};
  margin-bottom: ${(props: IColProps) => props.marginBottom};
  flex-wrap: ${(props: IColProps) => props.wrap};
  padding: ${(props: IColProps) => props.padding};
  max-width: ${(props: IColProps) => props.maxWidth};
  border: ${(props) => props.border};
  overflow: ${(props) => props.overflow};
  max-height: ${(props: IColProps) => props.maxHeight};
  flex: ${(props) => props.flex};
  flex-wrap: ${(props) => props.flexWrap};
  flex-direction: ${(props) => (props.flexDirection ? 'column' : 'row')};
  cursor: ${(props) => (props.cursorPointer ? 'pointer' : '')};
  min-width: ${(props) => props.minWidth};
`

export const SimpleIcon = styled.img<ISimpleIconProps>`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
`
export const ButtonIcon = styled(SimpleIcon)`
  transition: transform 0.2s;
  :hover {
    cursor: pointer;
    transform: scale(1.1);
  }
`

export const CircularIconButton = styled(ButtonIcon)<ICircularIconButton>`
  height: ${(props) => props.height ?? '30px'};
  width: ${(props) => props.height ?? '30px'};
  padding: ${(props) => props.padding ?? '8px'};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.secondary};
  box-shadow: ${(props) =>
    !props.hideShadow ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : ''};
  overflow: visible;
  border: ${(props) =>
    props.hideShadow ? `1px solid ${props.theme.borderColor}` : ''};
  opacity: ${(props) => props.opacity};
  font-size: 15px;
  line-height: 22px;
  color: ${(props) => props.color ?? props.theme.primaryText};
  font-weight: 500;
`

export const GlobalLoaderContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background: rgb(255 255 255 / 70%);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
`

interface IAnimatedLoaderProps {
  loaderColor?: string
  size?: string
}
export const AnimatedLoader = styled.div<IAnimatedLoaderProps>`
  display: inline-block;
  position: relative;
  width: ${(props) => props.size ?? '80px'};
  height: ${(props) => props.size ?? '80px'};
  display: flex;
  justify-content: center;
  align-items: center;
  :after {
    content: ' ';
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 8px;
    box-sizing: border-box;
    border: ${(props) => props.size ?? '32px'} solid
      ${(props) => props.loaderColor ?? '#fff'};
    border-color: ${(props) => props.loaderColor ?? '#fff'} transparent
      ${(props) => props.loaderColor ?? '#fff'} transparent;
    animation: lds-hourglass 1.2s infinite;
  }
  @keyframes lds-hourglass {
    0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
      transform: rotate(1800deg);
    }
  }
`

export const SuccessSubContainer = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  gap: 0px;
  width: 100%;
  @media (min-width: ${screenSizes.mediaSM}px) {
    min-width: 528px;
    max-width: 528px;
  }
`

const pulse = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`
export const SkeletonWrapper = styled.div<ISkeletonProps>`
  background-color: #f2f2f2;
  border-radius: ${(props) => props.borderRadius ?? '4px'};
  width: ${(props) => props.width ?? '100%'};
  height: ${(props) => props.height ?? '16px'};
  animation: ${pulse} 1s ease-in-out infinite;
`

export const HorizontalLine = styled.div<ILineProps>`
  width: 100%;
  margin: ${(props) => props.margin};
  border-bottom: 1px solid ${(props) => props.color ?? props.theme.borderColor};
  opacity: ${(props) => props.opacity};
`

export const VerticalLine = styled.div`
  border: 1px solid ${(props) => props.theme.primaryText};
`

interface ITabItemProps {
  isDisabled?: boolean
}
export const TabItem = styled.div<ITabItemProps>`
  display: flex;
  height: 38px;
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'auto')};
  opacity: ${(props) => (props.isDisabled ? 0.75 : 1)};
  :hover {
    cursor: pointer;
  }
`

export const TableHeadItem = styled(Row)`
  display: flex;
  align-items: center;

  .sortIcon {
    width: 8px;
    cursor: pointer;
  }

  .arrowIcon {
    width: 8px;
    cursor: pointer;
  }
`

export const TableItemRow = styled(Row)`
  .ellipsis {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px;
  }

  .check-badge {
    height: 14px;
    width: 14px;
    margin-left: -6px;
  }

  @media (min-width: ${screenSizes.mediaXXL}px) {
    p {
      font-size: 14px;
    }
  }
`

export const TimeAgoText = styled.span`
  font-size: 11px;
  line-height: 16px;
  font-weight: 500;
  font-style: medium;
  font-family: 'Poppins';
`

export const SkeletonLoader = styled.div<ILoaderProps>`
  background-color: ${({ backgroundColor }) => backgroundColor ?? `#d9d9d9`};
  border-radius: ${({ borderRadius }) => borderRadius ?? '6px'};
  width: ${({ width }) => width ?? '100%'};
  height: ${({ height }) => height ?? '16px'};
  animation: ${pulse} 1s ease-in-out infinite;
  overflow: hidden;
  margin: ${({ margin }) =>
    !!margin &&
    css`
      ${margin}
    `};
`

export const OutlinedStyle = css<IStyledTextarea>`
  border: 1px solid ${(props) => props.borderColor ?? props.theme.borderLight};
  font-weight: ${(props) => props.fontWeight ?? null};
  border-radius: ${(props) => props?.borderRadius ?? `10px`};

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  :focus-within {
    border: 1px solid ${(props) => props.borderColor ?? props.theme.textColor3};
    /* border: none; */
  }

  :focus {
    outline: none;
  }

  ::placeholder {
    color: ${(props) => props.theme.icon};
    opacity: 1; /* Firefox */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    opacity: 0.6;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${(props) => props.theme.icon};
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    opacity: 0.6;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${(props) => props.theme.icon};
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    opacity: 0.6;
  }
`

export const errorStyle = css`
  border: 1px solid ${(props) => props.theme.error};
  border: 1px solid ${(props) => props.theme.error};
  :focus-within {
    border: 1px solid ${(props) => props.theme.error};
  }

  :hover {
    border: 1px solid ${(props) => props.theme.error};
  }
`

export const accentColorStyle = css<IStyledTextarea>`
  :focus-within {
    border: 1px solid ${(props) => props.accentColor};
    box-shadow: 0 0 3px 0 ${(props) => props.accentColor};
  }

  :hover {
    border: 1px solid ${(props) => props.accentColor};
    box-shadow: 0 0 3px 0 ${(props) => props.accentColor};
  }
`

export const visitedStyle = css<IStyledTextarea>`
  background: ${(props) => props.theme?.primaryText}0D;
`

export const BannerPreview = styled.img`
  width: 60px;
  height: 45px;
  object-fit: cover;
  border-radius: 3px;
`

export const CommonSectionWrap = styled.div`
  max-width: ${maxWidth}px;
  padding: 0px ${globalPadding}px;
  margin: auto;
  width: 100%;

  @media (max-width: ${screenSizes.mediaXL}px) {
    padding: 0px 68px;
  }
  @media (max-width: ${screenSizes.mediaSS}px) {
    padding: 0px 20px;
  }
`
