import styled from 'styled-components'

export const PaginationContainer = styled.nav`
  display: flex;
  gap: 15px;
  justify-content: end;
  align-items: center;
  ul.pagination {
    display: flex;
    gap: 12px;
    list-style-type: none;
    align-items: center;
  }

  .recordsPerPage {
    width: 80px;
  }

  .dropdownHeader {
    height: 30px;
    background: ${(props) => props.theme.borderColor};
  }
`
interface IPageNavBtnProps {
  isDisabled?: boolean
}
export const PageNavBtn = styled.div<IPageNavBtnProps>`
  :hover {
    cursor: pointer;
  }
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'unset')};
`

export const PageNumberBtn = styled.div<IPageNavBtnProps>`
  :hover {
    cursor: pointer;
  }
  color: ${(props) =>
    props.isDisabled ? props.theme.primaryText : props.theme.primary};
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'unset')};
  font-size: ${(props) => (props.isDisabled ? '16px' : '20px')};
`

export const Pipe = styled.div`
  border: 1px solid ${(props) => props.theme.primaryText};
  height: 100%;
`
export const NumberInput = styled.input`
  width: 40px;
  height: 21px;
`
export const DragOptionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
  gap: 6px;
  width: 50px;
`
